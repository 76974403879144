import React, { FunctionComponent, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect, useParams } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormGroup,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Key, Lock } from 'react-feather';

import { updatePassword } from 'redux/actions';
import { isUserAuthenticated } from 'helpers/authUtils';
import Loader from 'components/Loader';
import logo from 'assets/images/tsl-logo-1.png';
import { RootState } from 'redux/reducers';
import { ApiLearning } from 'helpers/api';

interface ResetPasswordWrapper {
  children: React.ReactNode;
}

const ResetPasswordWrapper: FunctionComponent<ResetPasswordWrapper> = ({ children }) => {
  const { resetId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  async function getLinkStatus(id) {
    try {
      setLoading(true);
      const { data } = await ApiLearning.get(`/password/reset/${id}`);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getLinkStatus(resetId);
  }, []);

  return (
    <div className="account-pages my-5">
      <Container>
        <Row className="justify-content-center">
          <Col xl={6}>
            <Card className="">
              <CardBody className="p-0">
                {loading && <Loader />}
                {!isEmpty(error) ? (
                  <Col className="p-3">
                    <div className="text-danger">{error}</div>
                  </Col>
                ) : (
                  <Row>{children}</Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col className="col-12 text-center">
            <p className="text-muted">Tarbiyah Sunnah Learning 2020</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const UpdatePasswordForm = () => {
  const { resetId } = useParams();
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const dispatch = useDispatch();
  const _updatePassword = useCallback(
    (password) => {
      dispatch(updatePassword(password, resetId));
    },
    [dispatch]
  );

  const handleValidSubmit = (event, values) => {
    if (values.password.length < 6) {
      alert('Minimal panjang password adalah 6 karakter!');
      return;
    }
    if (values.password != values.retypePassword) {
      alert('Password yg diketik ulang tidak sesuai!');
      return;
    }
    _updatePassword(values.password);
  };

  return (
    <AvForm onValidSubmit={handleValidSubmit} className="authentication-form">
      <AvGroup className="">
        <Label for="password">Password Baru</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <span className="input-group-text">
              <Key className="icon-dual" />
            </span>
          </InputGroupAddon>
          <AvInput
            type="password"
            name="password"
            id="password"
            placeholder="minimal 6 karakter"
            value={password}
            required
          />
        </InputGroup>
        <AvFeedback>This field is invalid</AvFeedback>
      </AvGroup>
      <AvGroup className="">
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <span className="input-group-text">
              <Key className="icon-dual" />
            </span>
          </InputGroupAddon>
          <AvInput
            type="password"
            name="retypePassword"
            id="retypePassword"
            placeholder="Ketik ulang password ..."
            value={retypePassword}
            required
          />
        </InputGroup>
        <AvFeedback>This field is invalid</AvFeedback>
      </AvGroup>

      <FormGroup className="form-group mb-0 text-center">
        <Button color="info" className="btn-block">
          Ubah Password
        </Button>
      </FormGroup>
    </AvForm>
  );
};

const ResetPassword: FunctionComponent = () => {
  const { loading, error, passwordUpdateStatus } = useSelector((state: RootState) => state.Auth);
  const isAuthenticated = isUserAuthenticated();
  const isSubmitSuccess = passwordUpdateStatus.code == 200;

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <ResetPasswordWrapper>
      <Col className="p-5 position-relative">
        {loading && <Loader />}

        <div className="text-center mb-1">
          <img src={logo} alt="" height="200" />
        </div>

        <h6 className="h5 mb-0 mt-1">Reset Password</h6>
        {isSubmitSuccess ? (
          <>
            <Alert color="success" isOpen style={{ marginTop: 10 }}>
              Password berhasil diubah. Silahkan login ulang.
            </Alert>
            <a href="/account/login">
              <Button>Login Ulang</Button>
            </a>
          </>
        ) : (
          <>
            <p className="text-muted mt-1 mb-4">
              Silahkan masukkan password baru antum dibawah (minimal 6 karakter)
            </p>

            {!isEmpty(error) && (
              <Alert color="danger" isOpen>
                <div>{error?.message}</div>
              </Alert>
            )}
            <UpdatePasswordForm />
          </>
        )}
      </Col>
    </ResetPasswordWrapper>
  );
};

export default ResetPassword;
