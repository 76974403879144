import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from './constants';

interface ShowNotificationParams {
  timeout?: number;
  color?: string;
  message: string;
}
export const showNotification = ({ timeout, color, message }: ShowNotificationParams) => ({
  type: SHOW_NOTIFICATION,
  payload: {
    timeout,
    color,
    message,
  },
});

export const hideNotification = () => ({
  type: HIDE_NOTIFICATION,
});
