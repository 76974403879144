import { TopbarNotifActionTypes, topbarNotifTypes } from './types';

import TopbarNotif from 'helpers/interfaces/TopbarNotif';

export const addTopbarNotif = (topbarNotif: TopbarNotif): TopbarNotifActionTypes => ({
  type: topbarNotifTypes.ADD_TOPBAR_NOTIF,
  payload: topbarNotif,
});

export const addMultiTopbarNotif = (topbarNotifs: TopbarNotif[]): TopbarNotifActionTypes => ({
  type: topbarNotifTypes.ADD_MULTI_TOPBAR_NOTIF,
  payload: topbarNotifs,
});

export const setTopbarNotifLoaded = (isLoaded: boolean): TopbarNotifActionTypes => ({
  type: topbarNotifTypes.SET_TOPBAR_NOTIF_LOADED,
  payload: isLoaded,
});

export const getTopbarNotif = (): TopbarNotifActionTypes => ({
  type: topbarNotifTypes.GET_TOPBAR_NOTIF,
});
