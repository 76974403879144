export const SET_CURRENT_STUDY_PLAN = 'SET_CURRENT_STUDY_PLAN';
export const GET_STUDY_PLAN = 'GET_STUDY_PLAN';
export const GET_STUDY_PLANS = 'GET_STUDY_PLANS';
export const GET_STUDY_PLANS_SUCCESS = 'GET_STUDY_PLANS_SUCCESS';
export const GET_STUDY_PLANS_FAILED = 'GET_STUDY_PLANS_FAILED';
export const CREATE_STUDY_PLAN = 'CREATE_STUDY_PLAN';
export const CREATE_STUDY_PLAN_SUCCESS = 'CREATE_STUDY_PLAN_SUCCESS';
export const CREATE_STUDY_PLAN_FAILED = 'CREATE_STUDY_PLAN_FAILED';
export const GET_STUDY_PLAN_COURSES = 'GET_STUDY_PLAN_COURSES';
export const GET_STUDY_PLAN_COURSES_SUCCESS = 'GET_STUDY_PLAN_COURSES_SUCCESS';
export const GET_STUDY_PLAN_COURSES_FAILED = 'GET_STUDY_PLAN_COURSES_FAILED';
export const GET_COURSE_BATCHES = 'GET_COURSE_BATCHES';
export const GET_COURSE_BATCHES_SUCCESS = 'GET_COURSE_BATCHES_SUCCESS';
export const GET_COURSE_BATCHES_FAILED = 'GET_COURSE_BATCHES_FAILED';
export const SUBMIT_STUDY_PLAN_COURSES = 'SUBMIT_STUDY_PLAN_COURSES';
export const SUBMIT_STUDY_PLAN_COURSES_SUCCESS = 'SUBMIT_STUDY_PLAN_COURSES_SUCCESS';
export const SUBMIT_STUDY_PLAN_COURSES_FAILED = 'SUBMIT_STUDY_PLAN_COURSES_FAILED';
