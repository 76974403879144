import React from 'react';
import Routes from 'routes/Routes';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { Alert } from 'reactstrap';

import 'assets/scss/theme.scss';

const FallbackComponent = ({ error, eventId }) => (
  <div className="m-3">
    {error?.name == 'ChunkLoadError' ? (
      <>
        <Alert color="warning">
          Aplikasi telah diupdate. Silahkan refresh browser anda dan coba lagi.
          <br />
          Time: {dayjs().format()}
        </Alert>
      </>
    ) : (
      <>
        <Alert color="danger">Ooops, ada sedikit masalah!</Alert>
        <p>
          <strong>Silahkan refresh browser dan coba lagi.</strong> Dan mohon pastikan koneksi
          internet anda sudah stabil.
        </p>
        <p>
          Jika kesalahan masih terjadi, silahkan hubungi Tim Support TSL dengan menyertakan info
          dibawah:
        </p>
        <p>
          <ul>
            <li>Time: {dayjs().format()}</li>
            <li>ID: {eventId}</li>
            <li>ErrorName: {error.name}</li>
            <li>ErrorMessage: {error.message}</li>
            <li>Stack: {error.stack}</li>
          </ul>
        </p>
      </>
    )}
  </div>
);

const App = () => (
  <Sentry.ErrorBoundary fallback={FallbackComponent}>
    <Routes />
  </Sentry.ErrorBoundary>
);

export default App;
