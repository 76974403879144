import { takeEvery, all, fork, call, put } from 'redux-saga/effects';
import { ApiLearning } from 'helpers/api';
import { ReduxAction } from 'redux/actions';
import { GET_MY_GROUPS } from './types';
import { getMyGroupsFailed, getMyGroupsSuccess } from './actions';

function* fetchMyGroups() {
  try {
    const response = yield call(ApiLearning.get, '/participants/my-group');
    yield put(getMyGroupsSuccess(response.data));
  } catch (err) {
    yield put(getMyGroupsFailed(err));
  }
}

function* watchFetchMyGroups() {
  yield takeEvery(GET_MY_GROUPS, fetchMyGroups);
}

function* classSaga() {
  yield all([fork(watchFetchMyGroups)]);
}

export default classSaga;
