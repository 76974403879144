import {
  GET_STUDY_PLAN,
  GET_STUDY_PLANS,
  GET_STUDY_PLANS_SUCCESS,
  GET_STUDY_PLANS_FAILED,
  GET_STUDY_PLAN_COURSES,
  GET_STUDY_PLAN_COURSES_SUCCESS,
  GET_STUDY_PLAN_COURSES_FAILED,
  CREATE_STUDY_PLAN,
  CREATE_STUDY_PLAN_SUCCESS,
  CREATE_STUDY_PLAN_FAILED,
  GET_COURSE_BATCHES,
  GET_COURSE_BATCHES_SUCCESS,
  GET_COURSE_BATCHES_FAILED,
  SET_CURRENT_STUDY_PLAN,
  SUBMIT_STUDY_PLAN_COURSES,
  SUBMIT_STUDY_PLAN_COURSES_SUCCESS,
  SUBMIT_STUDY_PLAN_COURSES_FAILED,
} from './constants';
import { CourseBatch, StudyPlanCourse, StudyPlan, StudyPlans } from 'helpers/interfaces/studyPlan';

type InitialState = {
  loading: boolean;
  loadingCourse: boolean;
  loadingBatch: boolean;
  submitting: boolean;
  error: any;
  currentStudyPlan?: StudyPlan;
  studyPlans: StudyPlans;
  planCourses: StudyPlanCourse[];
  courseBatches: CourseBatch[];
};

const INIT_STATE: InitialState = {
  loading: false,
  loadingCourse: false,
  loadingBatch: false,
  submitting: false,
  error: null,
  currentStudyPlan: undefined,
  studyPlans: [],
  planCourses: [],
  courseBatches: [],
};

const irs = (state: InitialState = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STUDY_PLAN:
      return { ...state, currentStudyPlan: undefined };
    case SET_CURRENT_STUDY_PLAN:
      return { ...state, currentStudyPlan: action.payload };
    case GET_STUDY_PLANS:
      return { ...state, loading: true, error: null };
    case GET_STUDY_PLANS_SUCCESS:
      return { ...state, loading: false, studyPlans: action.payload };
    case GET_STUDY_PLANS_FAILED:
      return { ...state, loading: false, error: action.payload };
    case GET_STUDY_PLAN_COURSES:
      return { ...state, loadingCourse: true, error: null, planCourses: [] };
    case GET_STUDY_PLAN_COURSES_SUCCESS:
      return { ...state, loadingCourse: false, planCourses: action.payload };
    case GET_STUDY_PLAN_COURSES_FAILED:
      return { ...state, loadingCourse: false, error: action.payload };
    case CREATE_STUDY_PLAN:
      return { ...state, submitting: true, error: null };
    case CREATE_STUDY_PLAN_SUCCESS:
      return { ...state, submitting: false };
    case CREATE_STUDY_PLAN_FAILED:
      return { ...state, submitting: false, error: action.payload };
    case GET_COURSE_BATCHES:
      return { ...state, loadingBatch: true, error: null, courseBatches: [] };
    case GET_COURSE_BATCHES_SUCCESS:
      return { ...state, loadingBatch: false, courseBatches: action.payload };
    case GET_COURSE_BATCHES_FAILED:
      return { ...state, loadingBatch: false, error: action.payload };
    case SUBMIT_STUDY_PLAN_COURSES:
      return { ...state, submitting: true, error: null };
    case SUBMIT_STUDY_PLAN_COURSES_SUCCESS:
      return { ...state, submitting: false };
    case SUBMIT_STUDY_PLAN_COURSES_FAILED:
      return { ...state, submitting: false, error: action.payload };
    default:
      return state;
  }
};

export default irs;
