import Error404 from 'pages/other/Error404';
import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { flattenedAuthRoutes, flattenedPrivateRoutes } from './index';
import { Spinner } from 'reactstrap';

const FullPageLayout = React.lazy(() => import('layouts/FullPageLayout'));
const DashboardLayout = React.lazy(() => import('layouts/DashboardLayout'));

const Loading = () => (
  <div className="d-flex justify-content-center align-items-center flex-column mt-3">
    <Spinner color="secondary" size="sm" />
    <p>Loading, mohon menunggu ...</p>
  </div>
);

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        {flattenedAuthRoutes.map((route, index) => {
          return (
            <route.route
              key={index}
              path={route.path}
              exact={route.exact}
              component={(props) => (
                <Suspense fallback={<Loading />}>
                  <FullPageLayout>
                    <route.component {...props} />
                  </FullPageLayout>
                </Suspense>
              )}
            />
          );
        })}
        {flattenedPrivateRoutes.map((route, index) => {
          return (
            <route.route
              key={index}
              path={route.path}
              exact={route.exact}
              component={(props) => (
                <Suspense fallback={<Loading />}>
                  <DashboardLayout>
                    <route.component {...props} />
                  </DashboardLayout>
                </Suspense>
              )}
            />
          );
        })}
        <Route path="*" component={Error404} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
