import React, { FunctionComponent, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormGroup,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Mail, Lock } from 'react-feather';

import { forgetPassword } from 'redux/actions';
import { isUserAuthenticated } from 'helpers/authUtils';
import Loader from 'components/Loader';
import logo from 'assets/images/tsl-logo-1.png';
import { RootState } from 'redux/reducers';

interface ForgotPasswordWrapper {
  children: React.ReactNode;
}

const ForgotPasswordWrapper: FunctionComponent<ForgotPasswordWrapper> = ({ children }) => (
  <div className="account-pages my-5">
    <Container>
      <Row className="justify-content-center">
        <Col xl={10}>
          <Card className="">
            <CardBody className="p-0">
              <Row>
                {children}
                <Col md={6} className="d-none d-md-inline-block">
                  <div className="auth-page-sidebar"></div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col className="col-12 text-center">
          <p className="text-muted">Tarbiyah Sunnah Learning 2020</p>
        </Col>
      </Row>
    </Container>
  </div>
);

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const _forgetPassword = useCallback(
    (email) => {
      dispatch(forgetPassword(email));
    },
    [dispatch]
  );

  const handleValidSubmit = (event, values) => {
    _forgetPassword(values.email);
  };

  return (
    <AvForm onValidSubmit={handleValidSubmit} className="authentication-form">
      <AvGroup className="">
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <span className="input-group-text">
              <Mail className="icon-dual" />
            </span>
          </InputGroupAddon>
          <AvInput
            type="text"
            name="email"
            id="email"
            placeholder="email@anda.com"
            value={email}
            required
          />
        </InputGroup>
        <AvFeedback>This field is invalid</AvFeedback>
      </AvGroup>

      <FormGroup className="form-group mb-0 text-center">
        <Button color="info" className="btn-block">
          Kirim Email
        </Button>
      </FormGroup>
    </AvForm>
  );
};

const ForgotPassword: FunctionComponent = () => {
  const { loading, error, passwordResetStatus } = useSelector((state: RootState) => state.Auth);
  const isAuthenticated = isUserAuthenticated();
  const isSubmitSuccess = passwordResetStatus.code == 200;

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <ForgotPasswordWrapper>
      <Col md={6} className="p-5 position-relative">
        {loading && <Loader />}

        <div className="text-center mb-5">
          <img src={logo} alt="" height="200" />
        </div>

        <h6 className="h5 mb-0 mt-4">Reset Password</h6>
        {isSubmitSuccess ? (
          <Alert color="success" isOpen style={{ marginTop: 10 }}>
            Kami telah mengirimkan instruksi reset password ke email antum. Silahkan dicek.
          </Alert>
        ) : (
          <>
            <p className="text-muted mt-1 mb-4">
              Masukkan email yg antum gunakan untuk mendaftar di bawah. Kami akan mengirimkan
              instruksi untuk reset password ke email tersebut.
            </p>

            {!isEmpty(error) && (
              <Alert color="danger" isOpen>
                <div>{error?.message}</div>
              </Alert>
            )}
            <ForgotPasswordForm />
          </>
        )}

        <p className="texttext-muted" style={{ marginTop: 10 }}>
          Kembali ke{' '}
          <Link to="/account/login" className="text-primary font-weight-bold ml-1">
            Login
          </Link>
        </p>
      </Col>
    </ForgotPasswordWrapper>
  );
};

export default ForgotPassword;
