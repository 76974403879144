import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Notification from './notification/reducers';
import CurrentQuiz from './currentQuiz/reducers';
import ClassReducer from './class/reducers';
import ProfileReducer from './profile/reducers';
import TopbarNotifReducer from './topbarNotif/reducers';
import IrsReducer from './irs/reducers';
import TranscriptReducer from './transcript/reducers';

const rootReducer = combineReducers({
  Auth,
  AppMenu,
  Notification,
  CurrentQuiz,
  Class: ClassReducer,
  Profile: ProfileReducer,
  TopbarNotif: TopbarNotifReducer,
  Irs: IrsReducer,
  Transcript: TranscriptReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
