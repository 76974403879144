import { takeEvery, all, fork, call, put, select } from 'redux-saga/effects';
import { REQUEST_QUIZ, SUBMIT_ANSWER } from './constants';
import { RequestQuiz, setCurrentQuiz, submitAnswerSuccessfull, resetCurrentQuiz } from './actions';
import { ReduxAction } from 'redux/actions';
import { ApiLearning } from 'helpers/api';
import { CurrentQuiz } from '../../helpers/interfaces/kelas';
import dayjs from 'dayjs';
import { RootState } from '../reducers';
import { showNotification } from '../notification/actions';

function* requestQuiz(action: ReduxAction<RequestQuiz>) {
  try {
    yield put(resetCurrentQuiz());
    yield put(setCurrentQuiz({ isLoading: true }));

    const { class_id, course_part_id, quiz_id } = action.payload;
    const response = yield call(ApiLearning.post, '/quiz-requests/request', {
      body: JSON.stringify({ quiz_id, course_part_id }),
    });

    /** Ini sementara hingga response code nya bukan 200 lagi. Kondisi ini harus diganti suatu saat nanti. tidak bisa seperti ini terus. */
    if (
      response.data === 'User Sudah Pernah Melakukan Permintaan Quiz Atau Batas Waktu Sudah Habis'
    ) {
      throw new Error(response.data);
    }

    // transform data
    const data: CurrentQuiz = {
      ...response.data,
      finished_at: dayjs().add(response.data.time, 'second').format('YYYY-MM-DD HH:mm:ss'),
      taken_at: dayjs(),
      questions: response.data.questions.map((v) => {
        return {
          ...v,
          answer_id: null,
        };
      }),
    };

    yield put(
      setCurrentQuiz({
        isLoading: false,
        canParticipate: true,
        class_id,
        ...data,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(
      setCurrentQuiz({ isLoading: false, canParticipate: false, errMessage: error.message })
    );
  }
}

function* submitAnswerFunc(action: ReduxAction<any>) {
  try {
    const currentQuiz = yield select((state: RootState) => state.CurrentQuiz);

    if (currentQuiz.isSubmitting || currentQuiz.isSubmitted) return; // Prevent multiple dispatch

    yield put(setCurrentQuiz({ isLoading: true, isSubmitting: true }));

    // jika lebih dari maximum waktu quiz
    let waktuPengerjaan = dayjs().diff(dayjs(currentQuiz.taken_at), 'second');
    if (waktuPengerjaan > currentQuiz.time) waktuPengerjaan = currentQuiz.time;

    /** Send API to submit quiz here */
    const payload = {
      quiz_id: currentQuiz.id,
      timer: waktuPengerjaan,
      answers: currentQuiz.questions.map((v) => ({
        question_id: v.id,
        answer_id: v.answer_id,
        is_essay: v.is_essay,
      })),
    };

    yield call(ApiLearning.post, `/quiz-submissions/save`, {
      body: JSON.stringify(payload),
    });

    /** Reset Quiz */
    yield put(submitAnswerSuccessfull());
    yield put(
      showNotification({
        message: 'Jawaban berhasil disimpan',
        timeout: 5000,
        color: 'success',
      })
    );
  } catch (error) {
    console.log(error);
    yield put(setCurrentQuiz({ isLoading: false, isSubmitting: false, finished_at: null }));
    yield put(
      showNotification({
        message: 'Terdapat kesalahan disaat menyimpan jawaban',
        timeout: 5000,
        color: 'danger',
      })
    );
  }
}

export function* watchRequestQuiz() {
  yield takeEvery(REQUEST_QUIZ, requestQuiz);
}

export function* watchSubmitAnswer() {
  yield takeEvery(SUBMIT_ANSWER, submitAnswerFunc);
}

function* currentQuizSaga() {
  yield all([fork(watchRequestQuiz)]);
  yield all([fork(watchSubmitAnswer)]);
}

export default currentQuizSaga;
