import { takeEvery, all, fork, call, put, select } from 'redux-saga/effects';
import { ApiLearning } from 'helpers/api';
import {
  CreateStudyPlan,
  RequestSubmitCourse,
  createStudyPlanFailed,
  createStudyPlanSuccess,
  getStudyPlanCoursesFailed,
  getStudyPlanCoursesSuccess,
  getStudyPlansFailed,
  getStudyPlansSuccess,
  getCourseBatchesFailed,
  getCourseBatchesSuccess,
  RequestGetCourseBatches,
  setCurrentStudyPlan,
  submitStudyPlanCoursesSuccess,
  submitStudyPlanCoursesFailed,
} from './actions';
import {
  CREATE_STUDY_PLAN,
  GET_COURSE_BATCHES,
  GET_STUDY_PLAN,
  GET_STUDY_PLANS,
  GET_STUDY_PLAN_COURSES,
  SUBMIT_STUDY_PLAN_COURSES,
} from './constants';
import { ReduxAction } from 'redux/actions';
import { RootState } from 'redux/reducers';
import { StudyPlans } from 'helpers/interfaces/studyPlan';

function* fetchStudyPlan(action) {
  try {
    const id = action.payload;
    let plans: StudyPlans = yield select((state: RootState) => state.Irs.studyPlans);

    if (plans.length == 0) {
      // fetch from network if we have no previous data
      const response = yield call(ApiLearning.post, '/participants/plan-studies');
      yield put(getStudyPlansSuccess(response.data.data));
      plans = response.data.data;
    }

    const plan = plans.find((it) => it.id == id);
    if (plan) {
      yield put(setCurrentStudyPlan(plan));
    }
  } catch (err) {
    yield put(getStudyPlansFailed(err));
  }
}

function* fetchStudyPlans() {
  try {
    const body = {
      sortBy: 'semester',
      desc: 'desc',
    };
    const response = yield call(ApiLearning.post, '/participants/plan-studies', {
      body: JSON.stringify(body),
    });
    yield put(getStudyPlansSuccess(response.data.data));
  } catch (err) {
    yield put(getStudyPlansFailed(err));
  }
}

function* createStudyPlan(action: ReduxAction<CreateStudyPlan>) {
  try {
    const response = yield call(ApiLearning.post, '/participants/plan-studies/save', {
      body: JSON.stringify(action.payload),
    });
    yield put(createStudyPlanSuccess(response.data));
    if (action.onSuccess) action.onSuccess();
  } catch (err) {
    yield put(createStudyPlanFailed(err));
  }
}

function* fetchStudyPlanCourses(action) {
  try {
    const body = {
      filter: {
        user_plan_study_id: action.payload,
      },
    };
    const response = yield call(ApiLearning.post, '/participants/plan-courses', {
      body: JSON.stringify(body),
    });
    yield put(getStudyPlanCoursesSuccess(response.data.data));
  } catch (err) {
    yield put(getStudyPlanCoursesFailed(err));
  }
}

function* fetchCourseBatches(action: ReduxAction<RequestGetCourseBatches>) {
  try {
    const body = {
      filter: {
        period: action.payload.period,
        semester: action.payload.semester,
      },
      sortBy: 'course_package_id',
    };
    const response = yield call(ApiLearning.post, '/participants/course-batches', {
      body: JSON.stringify(body),
    });
    yield put(getCourseBatchesSuccess(response.data.data));
  } catch (err) {
    yield put(getCourseBatchesFailed(err));
  }
}

function* submitStudyPlanCourses(action: ReduxAction<any>) {
  try {
    const { added, removed } = action.payload;
    yield all(
      added.map((course) =>
        call(ApiLearning.post, '/participants/plan-courses/save', {
          body: JSON.stringify(course),
        })
      )
    );
    yield all(
      removed.map((course) => call(ApiLearning.delete, `/participants/plan-courses/${course.id}`))
    );
    yield put(submitStudyPlanCoursesSuccess());
    if (action.onSuccess) action.onSuccess();
  } catch (err) {
    yield put(submitStudyPlanCoursesFailed(err));
  }
}

function* watchFetchStudyPlan() {
  yield takeEvery(GET_STUDY_PLAN, fetchStudyPlan);
}
function* watchFetchStudyPlans() {
  yield takeEvery(GET_STUDY_PLANS, fetchStudyPlans);
}
function* watchCreateStudyPlan() {
  yield takeEvery(CREATE_STUDY_PLAN, createStudyPlan);
}
function* watchFetchStudyPlanCourses() {
  yield takeEvery(GET_STUDY_PLAN_COURSES, fetchStudyPlanCourses);
}
function* watchFetchCourseBatches() {
  yield takeEvery(GET_COURSE_BATCHES, fetchCourseBatches);
}
function* watchSubmitStudyPlanCourses() {
  yield takeEvery(SUBMIT_STUDY_PLAN_COURSES, submitStudyPlanCourses);
}

function* irsSaga() {
  yield all([
    fork(watchFetchStudyPlan),
    fork(watchFetchStudyPlans),
    fork(watchCreateStudyPlan),
    fork(watchFetchStudyPlanCourses),
    fork(watchFetchCourseBatches),
    fork(watchSubmitStudyPlanCourses),
  ]);
}

export default irsSaga;
