import TopbarNotif from 'helpers/interfaces/TopbarNotif';

interface TopbarNotifTypes {
  ADD_TOPBAR_NOTIF: 'ADD_TOPBAR_NOTIF';
  ADD_MULTI_TOPBAR_NOTIF: 'ADD_MULTI_TOPBAR_NOTIF';
  GET_TOPBAR_NOTIF: 'GET_TOPBAR_NOTIF';
  SET_TOPBAR_NOTIF_LOADED: 'SET_TOPBAR_NOTIF_LOADED';
}

export const topbarNotifTypes: TopbarNotifTypes = {
  ADD_TOPBAR_NOTIF: 'ADD_TOPBAR_NOTIF',
  ADD_MULTI_TOPBAR_NOTIF: 'ADD_MULTI_TOPBAR_NOTIF',
  GET_TOPBAR_NOTIF: 'GET_TOPBAR_NOTIF',
  SET_TOPBAR_NOTIF_LOADED: 'SET_TOPBAR_NOTIF_LOADED',
};

export interface TopbarNotifState {
  id: number;
  icon: string;
  text: string;
  subText: string;
  bgColor: string;
}

export interface TopbarNotifStateCapsule {
  isLoaded: boolean;
  topbarNotif: TopbarNotifState[];
}

interface AddTopbarNotifAction {
  type: typeof topbarNotifTypes.ADD_TOPBAR_NOTIF;
  payload: TopbarNotif;
}

interface AddMultipleTopbarNotifAction {
  type: typeof topbarNotifTypes.ADD_MULTI_TOPBAR_NOTIF;
  payload: TopbarNotif[];
}

interface SetTopbarNotifLoadedAction {
  type: typeof topbarNotifTypes.SET_TOPBAR_NOTIF_LOADED;
  payload: boolean;
}

interface GetTopbarNotifAction {
  type: typeof topbarNotifTypes.GET_TOPBAR_NOTIF;
}

export type TopbarNotifActionTypes =
  | AddTopbarNotifAction
  | AddMultipleTopbarNotifAction
  | SetTopbarNotifLoadedAction
  | GetTopbarNotifAction;
