import { GET_TRANSCRIPTS, GET_TRANSCRIPTS_SUCCESS, GET_TRANSCRIPTS_FAILED } from './constants';
import { UserIPK, Transcripts } from 'helpers/interfaces/transcript';

type InitialState = {
  loading: boolean;
  error: any;
  ipk?: UserIPK;
  transcripts: Transcripts;
};

const INIT_STATE: InitialState = {
  loading: false,
  error: null,
  ipk: undefined,
  transcripts: [],
};

const transcript = (state: InitialState = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRANSCRIPTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_TRANSCRIPTS_SUCCESS:
      return {
        ...state,
        loading: false,
        ipk: action.payload.summary_score,
        transcripts: action.payload.course,
      };
    case GET_TRANSCRIPTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default transcript;
