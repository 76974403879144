import {
  SET_CURRENT_CLASS,
  ClassState,
  ClassActionTypes,
  SET_CURRENT_COURSE,
  SET_CURRENT_COURSE_PART,
  GET_MY_GROUPS,
  GET_MY_GROUPS_SUCCESS,
  GET_MY_GROUPS_FAILED,
} from './types';

const initialState: ClassState = {
  currentClass: {
    id: '',
    name: '',
    courses: [],
  },
  currentCourse: {
    id: '',
  },
  currentCoursePart: {
    id: '',
  },
  groups: [],
  loading: false,
  error: null,
};

export default function classReducer(state = initialState, action: ClassActionTypes): ClassState {
  switch (action.type) {
    case SET_CURRENT_CLASS:
      return { ...state, currentClass: action.payload };
    case SET_CURRENT_COURSE:
      return { ...state, currentCourse: action.payload };
    case SET_CURRENT_COURSE_PART:
      return { ...state, currentCoursePart: action.payload };
    case GET_MY_GROUPS:
      return { ...state, loading: true, error: null };
    case GET_MY_GROUPS_SUCCESS:
      return { ...state, loading: false, groups: action.payload };
    case GET_MY_GROUPS_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
