import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import { persistStore, persistReducer } from 'redux-persist';
import secureLS from '../helpers/secureLS';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const persistConfig = {
  key: 'tsl-pembelajaran',
  storage: secureLS,
  whitelist: ['CurrentQuiz', 'Class'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export function configureStore(initialState: {} = {}) {
  const composeEnhancers =
    (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  sagaMiddleware.run(sagas, store);

  const persistor = persistStore(store);

  return { store, persistor };
}
