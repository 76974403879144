import React, { useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

//import { EventInput } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import BootstrapTheme from '@fullcalendar/bootstrap';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';

import PageTitle from '../../components/PageTitle';
import { ApiLearning } from 'helpers/api';
import moment from 'moment';
import EventInfoModal from './EventInfoModal';

const thisUri = '/kalender';
const apiUri = '/academic-calendars';

export const CalendarApp: React.FC = () => {
  // const events: CalendarEvent[] = [
  //   {
  //     id: 1,
  //     title: 'UAS',
  //     start: new Date().setDate(new Date().getDate() + 1),
  //     end: new Date().setDate(new Date().getDate() + 5),
  //     className: 'bg-warning text-white',
  //     extendedProps: {
  //       description: 'Lorem Ipsum Dolor sit Amet',
  //     },
  //   },
  //   {
  //     id: 2,
  //     title: 'Libur Nasional',
  //     start: new Date().setDate(new Date().getDate() + 8),
  //     end: new Date().setDate(new Date().getDate() + 9),
  //     className: 'bg-danger text-white',
  //     extendedProps: {
  //       description: 'Lorem Ipsum Dolor sit Amet',
  //     },
  //   },
  // ];

  const [showCreateModal, setShowCreateModal] = useState(false);
  // const [eventData, setEventData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [currCalendarTitle, setCurrCalendarTitle] = useState('');

  const getAcademicCalendar = async (start_date, end_date) => {
    const uri = apiUri;
    const body = {
      filter: {
        start_date: {
          gte: moment(start_date).format('YYYY-MM-DD').toString(),
        },
        end_date: {
          lte: moment(end_date).format('YYYY-MM-DD').toString(),
        },
      },
    };
    const {
      data: { data },
    } = await ApiLearning.post(uri, { body: JSON.stringify(body) });

    const result = data.map((v, i) => ({
      id: v.id,
      title: v.title,
      description: v.description,
      start: moment(v.start_date + ' ' + v.start_time, 'YYYY-MM-DD HH:mm').toDate(),
      end: moment(v.end_date + ' ' + v.end_time, 'YYYY-MM-DD HH:mm').toDate(),
      className: 'text-white',
      backgroundColor: v.color,
      extendedProps: {
        descripton: v.description,
      },
    }));

    // console.log(result);
    return result;
  };

  const handleEventClick = (eventInfo: any) => {
    setShowCreateModal(true);
    const data = eventInfo.event;
    setSelectedEvent({
      id: data.id,
      title: data.title,
      description: data.extendedProps.description,
      start: data.start,
      end: data.end,
      color: data.backgroundColor,
    });
  };

  const getEvents = (fetchInfo: any) => {
    if (!showCreateModal) {
      return getAcademicCalendar(fetchInfo.start, fetchInfo.end);
    }
  };

  return (
    <React.Fragment>
      <Row className="page-title align-items-center">
        <Col xs={12}>
          <h3 className="mb-1 mt-0">Kalender Akademik</h3>
        </Col>
      </Row>
      <Row className="page-title align-items-center">
        <Col xs={12} className="text-center">
          <h3 className="mb-0 mt-0">{currCalendarTitle}</h3>
        </Col>
      </Row>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              {/* fullcalendar control */}
              <FullCalendar
                defaultView="dayGridMonth"
                plugins={[
                  BootstrapTheme,
                  dayGridPlugin,
                  interactionPlugin,
                  timeGridPlugin,
                  listPlugin,
                ]}
                // slotDuration="00:15:00"
                // minTime="08:00:00"
                // maxTime="19:00:00"
                themeSystem="bootstrap"
                // handleWindowResize={true}
                bootstrapFontAwesome={false}
                buttonText={{
                  today: 'Today',
                  month: 'Month',
                  week: 'Week',
                  day: 'Day',
                  list: 'List',
                  prev: '<',
                  next: '>',
                }}
                header={{
                  left: 'prev,next today',
                  center: '',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
                }}
                datesRender={(info) => {
                  const view = info.view;
                  setCurrCalendarTitle(view.title);
                }}
                contentHeight={'auto'}
                eventClick={handleEventClick}
                editable={true}
                // eventLimit={true} // allow "more" link when too many events
                selectable={true}
                // events={eventData}
                events={getEvents}
              />
            </CardBody>
          </Card>
        </Col>
        {/* <EventInfoModal
          isOpen={visibleModal === 'eventInfo'}
          toggle={() => toggleModal('eventInfo')}
          onNextClick={handleNextClick}
          onPrevClick={handlePrevClick}
          selectedDate={selectedDate}
          events={eventData}
        /> */}
        <EventInfoModal
          thisUri={thisUri}
          apiUri={apiUri}
          selectedEvent={selectedEvent}
          toggle={() => setShowCreateModal(!showCreateModal)}
          isOpen={showCreateModal}
        />
      </Row>
    </React.Fragment>
  );
};

export default CalendarApp;
