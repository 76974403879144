import { ProfileActionTypes, profileTypes } from './types';
import { Profile } from 'helpers/interfaces/user';

export const getProfile = (): ProfileActionTypes => ({
  type: profileTypes.GET_PROFILE,
});

export const getProfileSuccess = (payload: Profile): ProfileActionTypes => ({
  type: profileTypes.GET_PROFILE_SUCESS,
  payload,
});

export const getProfileError = (error: Error): ProfileActionTypes => ({
  type: profileTypes.GET_PROFILE_ERROR,
  payload: error,
});

export const resetGetProfile = (): ProfileActionTypes => ({
  type: profileTypes.RESET_GET_PROFILE,
});
