import { Profile } from 'helpers/interfaces/user';
import { ProfileActionTypes, profileTypes } from './types';
import { RequestStatus } from 'helpers/enums';

interface State {
  profile: Profile;
  status: RequestStatus;
  error?: Error;
}

const initialState = {
  profile: {
    address: '',
    age: 0,
    birth_date: new Date(),
    birth_place: '',
    city: {
      id: 0,
      name: '',
    },
    city_id: 0,
    country: {
      id: 0,
      name: '',
      phone_code: '',
    },
    country_id: 0,
    created_at: '',
    deleted_at: null,
    education_id: 0,
    education_name: '',
    school_name: '',
    study_program: '',
    email: '',
    entry_level_id: null,
    gender: '',
    id: '',
    isCompleteDataProfile: false,
    job_id: 0,
    job_name: '',
    marital_status: '',
    name: '',
    nip: '',
    phone: '',
    phone_country_code: '',
    state: {
      id: 0,
      name: '',
    },
    status: 'inactive',
    is_verification_wa: false,
  },
  status: RequestStatus.Initial,
  error: undefined,
};

const profile = (state: State = initialState, action: ProfileActionTypes): State => {
  switch (action.type) {
    case profileTypes.GET_PROFILE:
      return { ...state, status: RequestStatus.Pending };
    case profileTypes.GET_PROFILE_SUCESS:
      return { ...state, profile: action.payload, status: RequestStatus.Successful };
    case profileTypes.GET_PROFILE_ERROR:
      return { ...state, error: action.payload, status: RequestStatus.Failed };
    case profileTypes.RESET_GET_PROFILE:
      return { ...state, error: undefined, status: RequestStatus.Initial };
    default:
      return state;
  }
};

export default profile;
