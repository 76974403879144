import { takeEvery, all, fork, call, put } from 'redux-saga/effects';
import { ApiLearning } from 'helpers/api';
import { getProfileSuccess, getProfileError, resetGetProfile } from './actions';
import { profileTypes } from './types';

function* fetchMyProfile() {
  yield put(resetGetProfile());
  try {
    const response = yield call(ApiLearning.get, '/me');

    yield put(getProfileSuccess(response.data));
  } catch (err) {
    yield put(getProfileError(err));
  }
}

function* watchFetchMyProfile() {
  yield takeEvery(profileTypes.GET_PROFILE, fetchMyProfile);
}

function* profileSaga() {
  yield all([fork(watchFetchMyProfile)]);
}

export default profileSaga;
