import {
  ClassActionTypes,
  GET_MY_GROUPS,
  GET_MY_GROUPS_FAILED,
  GET_MY_GROUPS_SUCCESS,
  SET_CURRENT_CLASS,
  SET_CURRENT_COURSE,
  SET_CURRENT_COURSE_PART,
} from './types';
import { Kelas, Course, CoursePart } from 'helpers/interfaces';

export function setCurrentClass(currentClass: Kelas): ClassActionTypes {
  return {
    type: SET_CURRENT_CLASS,
    payload: currentClass,
  };
}
export function setCurrentCourse(currentCourse: Course): ClassActionTypes {
  return {
    type: SET_CURRENT_COURSE,
    payload: currentCourse,
  };
}
export function setCurrentCoursePart(currentCoursePart: CoursePart): ClassActionTypes {
  return {
    type: SET_CURRENT_COURSE_PART,
    payload: currentCoursePart,
  };
}

export function getMyGroups() {
  return {
    type: GET_MY_GROUPS,
  };
}
export function getMyGroupsSuccess(data) {
  return {
    type: GET_MY_GROUPS_SUCCESS,
    payload: data,
  };
}
export function getMyGroupsFailed(error) {
  return {
    type: GET_MY_GROUPS_FAILED,
    payload: error,
  };
}
