import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

import { Provider } from 'react-redux';
import { configureStore } from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';

const { store, persistor } = configureStore();

Sentry.init({
  dsn: 'https://e8d781ce4c9646a1a825322075ddc9d9@sentry.kampustsl.id/3',
  // This enables automatic instrumentation (highly recommeneded), but is not
  // necessary for purely manual usage
  integrations: [new TracingIntegrations.BrowserTracing()],
  // To set a uniform sample rate
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
