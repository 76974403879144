import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import dayjs from 'dayjs';
import { dayjsLocalize } from 'helpers/timeUtils';

interface EventInfoModalProps {
  thisUri: string;
  apiUri: string;
  toggle: () => void;
  isOpen: boolean;
  selectedEvent;
}

const EventInfoModal: React.FC<EventInfoModalProps> = (props) => {
  const { toggle, selectedEvent } = props;

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <div className="d-flex align-items-center">
        <div className="flex-fill">
          <ModalHeader>{selectedEvent.title}</ModalHeader>
        </div>
      </div>
      <ModalBody>
        <div className="table">
          <table className="table table-responsive">
            <tbody>
              <tr>
                <td>Mulai : </td>
                <td>{dayjsLocalize(selectedEvent.start).format('DD MMMM YYYY HH:mm:ss')}</td>
              </tr>
              <tr>
                <td>Berakhir : </td>
                <td>{dayjsLocalize(selectedEvent.end).format('DD MMMM YYYY HH:mm:ss')}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p dangerouslySetInnerHTML={{ __html: selectedEvent.description }} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Tutup</Button>
      </ModalFooter>
    </Modal>
  );
};

export default EventInfoModal;
