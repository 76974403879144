import { GET_TRANSCRIPTS, GET_TRANSCRIPTS_FAILED, GET_TRANSCRIPTS_SUCCESS } from './constants';
import { ReduxAction } from 'redux/actions';

export interface RequestTranscript {
  transcript_type: boolean;
}

export const getTranscripts = (payload: RequestTranscript): ReduxAction<RequestTranscript> => ({
  type: GET_TRANSCRIPTS,
  payload,
});
export const getTranscriptsSuccess = (data) => ({
  type: GET_TRANSCRIPTS_SUCCESS,
  payload: data,
});
export const getTranscriptsFailed = (error) => ({
  type: GET_TRANSCRIPTS_FAILED,
  payload: error,
});
