import cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from 'helpers/api';
import config from 'helpers/config';
import csrfService from 'helpers/axios/CsrfAuthService';

import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  FORGET_PASSWORD,
  UPDATE_PASSWORD,
} from 'redux/auth/constants';
import {
  loginUserSuccess,
  loginUserFailed,
  registerUserSuccess,
  registerUserFailed,
  forgetPasswordSuccess,
  forgetPasswordFailed,
  updatePasswordSuccess,
  updatePasswordFailed,
} from 'redux/auth/actions';
import { getProfileSuccess } from 'redux/profile/actions';

const setSession = (user) => {
  if (isEmpty(user)) {
    cookies.remove('user', { path: '/' });
  } else {
    cookies.set('user', JSON.stringify(user), { path: '/' });
  }
};

type LoginAction = {
  payload: {
    username: string;
    password: string;
    grant_type: string;
  };
  type: typeof LOGIN_USER;
};

function* login(action: LoginAction) {
  const { username, password, grant_type } = action.payload;
  const options = {
    body: JSON.stringify({ username, password, grant_type }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    yield call(() => csrfService.getSanctum('sanctum/csrf-cookie'));
    const response = yield call(fetchJSON, `${config.apiUrl}/login-peserta`, options);
    const data = response.data;
    yield put(getProfileSuccess(data));
    const user = {
      id: data.id,
      nip: data.nip,
      name: data.name,
      role: data.role.name,
      token: data.token,
      token_expire: data.expire_token,
    };
    setSession(user);
    yield put(loginUserSuccess(response));
  } catch (error) {
    yield put(loginUserFailed(error));
    setSession({});
  }
}

type LogoutAction = {
  type: typeof LOGOUT_USER;
  payload: {
    history: any;
  };
};

function* logout(action: LogoutAction) {
  const { history } = action.payload;
  try {
    setSession({});
    yield call(() => {
      history.push('/account/login');
    });
  } catch (error) {}
}

type RegisterAction = {
  type: typeof REGISTER_USER;
  payload: {
    fullname: string;
    email: string;
    password: string;
  };
};

function* register(action: RegisterAction) {
  const { fullname, email, password } = action.payload;
  const options = {
    body: JSON.stringify({ fullname, email, password }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const response = yield call(fetchJSON, '/users/register', options);
    yield put(registerUserSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(registerUserFailed(message));
  }
}

type ForgetPasswordAction = {
  type: typeof FORGET_PASSWORD;
  payload: {
    email: string;
  };
};

function* forgetPassword(action: ForgetPasswordAction) {
  const { email } = action.payload;
  const options = {
    body: JSON.stringify({ email }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const response = yield call(fetchJSON, `${config.apiUrl}/password/reset`, options);
    yield put(forgetPasswordSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(forgetPasswordFailed(message));
  }
}

type UpdatePasswordAction = {
  type: typeof UPDATE_PASSWORD;
  payload: {
    new_password: string;
    reset_id: string;
  };
};

function* updatePassword(action: UpdatePasswordAction) {
  const { new_password, reset_id } = action.payload;
  const options = {
    body: JSON.stringify({ new_password }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const response = yield call(fetchJSON, `${config.apiUrl}/password/reset/${reset_id}`, options);
    yield put(updatePasswordSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(updatePasswordFailed(message));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchUpdatePassword() {
  yield takeEvery(UPDATE_PASSWORD, updatePassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgetPassword),
    fork(watchUpdatePassword),
  ]);
}

export default authSaga;
