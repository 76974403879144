import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

// fungsi ini utk merubah datetime dari server menjadi local
// misal di server jam 09:30:00
// maka di browser user (misal di Irian Jaya +2) => jam 11:30:00
export const dayjsLocalize = (datetimeStringFromServer): any => {
  // assume server time is set to +07:00
  datetimeStringFromServer = datetimeStringFromServer + ' +07:00';
  return dayjs(datetimeStringFromServer, 'YYYY-MM-DD HH:mm:ss Z');
};
