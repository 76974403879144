import jwtDecode from 'jwt-decode';
import cookies from 'js-cookie';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

export const isUserAuthenticated = () => {
  const token = getToken();
  if (isEmpty(token)) {
    return false;
  }

  const tokenExpiringTimestamps = getTokenExpiration();
  const tokenExpiringTime = dayjs.unix(tokenExpiringTimestamps);
  const currentTime = dayjs();

  if (tokenExpiringTime < currentTime) {
    cookies.remove('user');
    return false;
  }

  return true;
};

interface User {
  id: string;
  nip: string;
  name: string;
  role: string;
  token: string;
  token_expire: number;
}

export const getLoggedInUser = (): User => {
  const user = cookies.get('user');
  return isEmpty(user) ? {} : JSON.parse(user as string);
};

export const getToken = (): string => {
  const user = getLoggedInUser();
  return user ? user.token : '';
};

export const getTokenExpiration = (): number => {
  const user = getLoggedInUser();
  return user ? user.token_expire : 0;
};
