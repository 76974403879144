import { WebStorage } from 'redux-persist';
import SecureLS from 'secure-ls';
import config from 'helpers/config';

class MyStorage implements WebStorage {
  private ls = new SecureLS({ encodingType: 'aes', encryptionSecret: config.secureLSSecret });

  getItem = async (key: string): Promise<string | null> => {
    return this.ls.get(key);
  };

  setItem = async (key: string, item: string): Promise<void> => {
    this.ls.set(key, item);
  };

  removeItem = async (key: string): Promise<void> => {
    this.ls.remove(key);
  };
}

export default MyStorage;
