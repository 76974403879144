import axios from 'axios';
import config from 'helpers/config';

const sanctumUrl = (apiUrl) => {
  const regExp = /(^|^[^:]+:\/\/|[^\.]+\.)(kampustsl|tslbelajarislam)\.id/;
  const baseURL = apiUrl.match(regExp);

  if (baseURL === null) {
    return 'http://localhost';
  } else {
    return baseURL[0];
  }
};
const axClient = axios.create({
  baseURL: sanctumUrl(config.apiUrl),
  xsrfHeaderName: 'X-XSRF-TOKEN',
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
});

export default axClient;
