import {
  SET_CURRENT_QUIZ,
  REQUEST_QUIZ,
  CHOOSE_ANSWER,
  SUBMIT_ANSWER,
  RESET_CURRENT_QUIZ,
  SUBMIT_ANSWER_SUCCESSFULL,
} from './constants';
import { ReduxAction } from 'redux/actions';
import { CurrentQuiz } from 'helpers/interfaces';

export interface RequestQuiz {
  class_id: string;
  course_part_id: string;
  quiz_id: string;
}
export const requestQuiz = (payload: RequestQuiz): ReduxAction<RequestQuiz> => ({
  type: REQUEST_QUIZ,
  payload,
});

export const setCurrentQuiz = (payload: CurrentQuiz): ReduxAction<CurrentQuiz> => ({
  type: SET_CURRENT_QUIZ,
  payload,
});

export interface ChooseAnswer {
  question_id: string;
  answer_id: string;
}

export const chooseAnswer = (payload: ChooseAnswer): ReduxAction<ChooseAnswer> => ({
  type: CHOOSE_ANSWER,
  payload,
});

export const submitAnswer = (): ReduxAction<any> => ({
  type: SUBMIT_ANSWER,
  payload: null,
});

export const submitAnswerSuccessfull = (): ReduxAction<any> => ({
  type: SUBMIT_ANSWER_SUCCESSFULL,
  payload: null,
});

export const resetCurrentQuiz = (): ReduxAction<any> => ({
  type: RESET_CURRENT_QUIZ,
  payload: null,
});
