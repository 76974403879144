import { takeEvery, all, fork, call, put } from 'redux-saga/effects';
import { ApiLearning } from 'helpers/api';
import { ReduxAction } from 'redux/actions';
import { getTranscriptsFailed, getTranscriptsSuccess, RequestTranscript } from './actions';
import { GET_TRANSCRIPTS } from './constants';

function* fetchTranscripts(action: ReduxAction<RequestTranscript>) {
  try {
    const { transcript_type } = action.payload;
    const response = yield call(ApiLearning.post, '/transcripts', {
      body: JSON.stringify({ transcript_type }),
    });
    yield put(getTranscriptsSuccess(response.data));
  } catch (err) {
    yield put(getTranscriptsFailed(err));
  }
}

function* watchFetchTranscripts() {
  yield takeEvery(GET_TRANSCRIPTS, fetchTranscripts);
}

function* transcriptSaga() {
  yield all([fork(watchFetchTranscripts)]);
}

export default transcriptSaga;
