import { Kelas, Course, CoursePart, Group } from 'helpers/interfaces';

export const SET_CURRENT_CLASS = 'SET_CURRENT_CLASS';
export const SET_CURRENT_COURSE = 'SET_CURRENT_COURSE';
export const SET_CURRENT_COURSE_PART = 'SET_CURRENT_COURSE_PART';
export const GET_MY_GROUPS = 'GET_MY_GROUPS';
export const GET_MY_GROUPS_SUCCESS = 'GET_MY_GROUPS_SUCCESS';
export const GET_MY_GROUPS_FAILED = 'GET_MY_GROUPS_FAILED';

export interface ClassState {
  currentClass: Kelas;
  currentCourse: Course;
  currentCoursePart: CoursePart;
  groups: Group[];
  loading: boolean;
  error: any;
}

interface SetCurrentClassAction {
  type: typeof SET_CURRENT_CLASS;
  payload: Kelas;
}

interface SetCurrentCourseAction {
  type: typeof SET_CURRENT_COURSE;
  payload: Course;
}

interface SetCurrentCoursePartAction {
  type: typeof SET_CURRENT_COURSE_PART;
  payload: CoursePart;
}

interface GetMyGroupsAction {
  type: typeof GET_MY_GROUPS;
}

interface GetMyGroupsSuccessAction {
  type: typeof GET_MY_GROUPS_SUCCESS;
  payload: Group[];
}

interface GetMyGroupsFailedAction {
  type: typeof GET_MY_GROUPS_FAILED;
  payload: any;
}

export type ClassActionTypes =
  | SetCurrentClassAction
  | SetCurrentCourseAction
  | SetCurrentCoursePartAction
  | GetMyGroupsAction
  | GetMyGroupsSuccessAction
  | GetMyGroupsFailedAction;
