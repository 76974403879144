import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import appMenuSaga from './appMenu/saga';
import currentQuizSaga from './currentQuiz/saga';
import profileSaga from './profile/saga';
import irsSaga from './irs/saga';
import transcriptSaga from './transcript/saga';
import classSaga from './class/saga';

export default function* rootSaga(getState: any): any {
  yield all([
    authSaga(),
    appMenuSaga(),
    currentQuizSaga(),
    profileSaga(),
    irsSaga(),
    transcriptSaga(),
    classSaga(),
  ]);
}
