import {
  SET_CURRENT_STUDY_PLAN,
  CREATE_STUDY_PLAN,
  CREATE_STUDY_PLAN_FAILED,
  CREATE_STUDY_PLAN_SUCCESS,
  GET_STUDY_PLAN,
  GET_STUDY_PLANS,
  GET_STUDY_PLANS_FAILED,
  GET_STUDY_PLANS_SUCCESS,
  GET_STUDY_PLAN_COURSES,
  GET_STUDY_PLAN_COURSES_FAILED,
  GET_STUDY_PLAN_COURSES_SUCCESS,
  GET_COURSE_BATCHES,
  GET_COURSE_BATCHES_FAILED,
  GET_COURSE_BATCHES_SUCCESS,
  SUBMIT_STUDY_PLAN_COURSES,
  SUBMIT_STUDY_PLAN_COURSES_SUCCESS,
  SUBMIT_STUDY_PLAN_COURSES_FAILED,
} from './constants';

export const getStudyPlan = (id) => ({
  type: GET_STUDY_PLAN,
  payload: id,
});
export const setCurrentStudyPlan = (data) => ({
  type: SET_CURRENT_STUDY_PLAN,
  payload: data,
});

export const getStudyPlans = () => ({
  type: GET_STUDY_PLANS,
});
export const getStudyPlansSuccess = (data) => ({
  type: GET_STUDY_PLANS_SUCCESS,
  payload: data,
});
export const getStudyPlansFailed = (error) => ({
  type: GET_STUDY_PLANS_FAILED,
  payload: error,
});

export const getStudyPlanCourses = (id) => ({
  type: GET_STUDY_PLAN_COURSES,
  payload: id,
});
export const getStudyPlanCoursesSuccess = (data) => ({
  type: GET_STUDY_PLAN_COURSES_SUCCESS,
  payload: data,
});
export const getStudyPlanCoursesFailed = (error) => ({
  type: GET_STUDY_PLAN_COURSES_FAILED,
  payload: error,
});

export interface CreateStudyPlan {
  period: string;
  semester: number;
  adviser_id: string;
}
export const createStudyPlan = (data: CreateStudyPlan, onSuccess) => ({
  type: CREATE_STUDY_PLAN,
  payload: data,
  onSuccess,
});
export const createStudyPlanSuccess = (data) => ({
  type: CREATE_STUDY_PLAN_SUCCESS,
  payload: data,
});
export const createStudyPlanFailed = (error) => ({
  type: CREATE_STUDY_PLAN_FAILED,
  payload: error,
});

export interface RequestGetCourseBatches {
  period: string;
  semester: number;
}
export const getCourseBatches = (data: RequestGetCourseBatches) => ({
  type: GET_COURSE_BATCHES,
  payload: data,
});
export const getCourseBatchesSuccess = (data) => ({
  type: GET_COURSE_BATCHES_SUCCESS,
  payload: data,
});
export const getCourseBatchesFailed = (error) => ({
  type: GET_COURSE_BATCHES_FAILED,
  payload: error,
});

export interface RequestSubmitCourse {
  id?: string;
  course_id: string;
  credit: string;
  user_plan_study_id: string;
}
export const submitStudyPlanCourses = (
  added: RequestSubmitCourse[],
  removed: RequestSubmitCourse[],
  onSuccess
) => ({
  type: SUBMIT_STUDY_PLAN_COURSES,
  payload: {
    added: added,
    removed: removed,
  },
  onSuccess,
});
export const submitStudyPlanCoursesSuccess = () => ({
  type: SUBMIT_STUDY_PLAN_COURSES_SUCCESS,
});
export const submitStudyPlanCoursesFailed = (error) => ({
  type: SUBMIT_STUDY_PLAN_COURSES_FAILED,
  payload: error,
});
