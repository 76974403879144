import {
  RESET_CURRENT_QUIZ,
  SET_CURRENT_QUIZ,
  CHOOSE_ANSWER,
  SUBMIT_ANSWER,
  SUBMIT_ANSWER_SUCCESSFULL,
} from './constants';
import { CurrentQuiz } from '../../helpers/interfaces/kelas';

const INIT_STATE: CurrentQuiz = {
  isLoading: false,
  isSubmitting: false,
  isSubmitted: false,
  canParticipate: false,
  class_id: null,
  id: null,
  course_id: null,
  course_part_id: null,
  title_id: null,
  time: null,
  time_open: null,
  time_close: null,
  category: null,
  finished_at: null,
  taken_at: null,
  questions: [],
  errMessage: null,
};

const currentQuiz = (state: CurrentQuiz = INIT_STATE, action): CurrentQuiz => {
  switch (action.type) {
    case SET_CURRENT_QUIZ:
      return {
        ...state,
        ...action.payload,
      };
    case CHOOSE_ANSWER:
      const newQuestions = state.questions!.map((v) => {
        if (v.id === action.payload.question_id) {
          v.answer_id = action.payload.answer_id;
        }
        return { ...v };
      });
      return {
        ...state,
        questions: newQuestions,
      };
    case SUBMIT_ANSWER:
      return {
        ...state,
      };
    case SUBMIT_ANSWER_SUCCESSFULL:
      return {
        ...INIT_STATE,
        isSubmitted: true,
        isSubmitting: false,
        isLoading: false,
      };
    case RESET_CURRENT_QUIZ:
      return INIT_STATE;
    default:
      return state;
  }
};

export default currentQuiz;
