/* eslint-disable react/display-name */

import React from 'react';
import { Redirect, RouteComponentProps, Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';
import CalendarApp from 'pages/calendar';

import { isUserAuthenticated } from 'helpers/authUtils';
import ForgetPassword from 'pages/auth/ForgetPassword';
import ResetPassword from 'pages/auth/ResetPassword';

const Login = React.lazy(() => import('pages/auth/Login'));
const Logout = React.lazy(() => import('pages/auth/Logout'));
const Dashboard = React.lazy(() => import('pages/dashboard'));
const ClassPage = React.lazy(() => import('pages/class/index'));
const ClassDetail = React.lazy(() => import('pages/class/ClassDetail'));
const MataKuliahDetail = React.lazy(() => import('pages/class/MataKuliahDetail'));
const SubMatkulDetail = React.lazy(() => import('pages/class/SubMatkulDetail'));
const MateriPelajaranDetail = React.lazy(() => import('pages/class/MateriPelajaranDetail'));
const UjianPage = React.lazy(() => import('pages/class/UjianPage'));
const FeedbackUjianPage = React.lazy(() => import('pages/class/FeedbackUjianPage'));
const Profile = React.lazy(() => import('pages/profile/index'));
const SyahadahPage = React.lazy(() => import('pages/syahadah/index'));
const StudentCardPage = React.lazy(() => import('pages/studentCard/index'));
const TranskripPage = React.lazy(() => import('pages/transkrip'));
const TranskripDetailPage = React.lazy(() => import('pages/transkrip/TranskripDetailPage'));
const SurveyPage = React.lazy(() => import('pages/survey'));
const SurveyDetailPage = React.lazy(() => import('pages/survey/SurveyDetail'));
const PrayerTimes = React.lazy(() => import('pages/prayerTimes/index'));
const IrsPage = React.lazy(() => import('pages/irs/index'));
const IrsFormPage = React.lazy(() => import('pages/irs/IrsFormPage'));
const IrsPlanPage = React.lazy(() => import('pages/irs/IrsPlanPage'));
const IrsPlanEditPage = React.lazy(() => import('pages/irs/IrsPlanEditPage'));
const Video = React.lazy(() => import('pages/video'));

interface PageRoutes {
  path: string;
  name: string;
  header?: string;
  icon?: React.ComponentType;
  route?: typeof PrivateRoute | typeof Route;
  component?: React.LazyExoticComponent<React.FC> | React.FC;
  children?: PageRoutes[];
  exact: boolean;
  // will hide it + every child from AppMenu component (LeftSidebar) if true
  disableFromMobileMenu?: boolean;
  disableFromMenu?: boolean;
  activeUserOnly?: boolean;
}

// handle auth and authorization
const PrivateRoute = ({ component: Component, path, exact }) => (
  <Route
    path={path}
    exact={exact}
    render={(props: RouteComponentProps) => {
      if (isUserAuthenticated()) {
        // authorised so return component
        return <Component {...props} />;
      }

      // not logged in so redirect to login page with the return url
      return <Redirect to={{ pathname: '/account/login' }} />;
    }}
  />
);

// root routes
const rootRoute: PageRoutes = {
  name: 'Root',
  path: '/',
  exact: true,
  disableFromMenu: true,
  component: () => <Redirect to="/dashboard" />,
  disableFromMobileMenu: true,
  route: PrivateRoute,
};

// dashboards
const dashboardRoutes: PageRoutes = {
  path: '/dashboard',
  name: 'Dashboard',
  icon: FeatherIcon.Home,
  header: 'Menu',
  component: Dashboard,
  route: PrivateRoute,
  exact: true,
  activeUserOnly: false,
};

const calendarAppRoutes: PageRoutes = {
  path: '/kalender',
  name: 'Kalender akademik',
  icon: FeatherIcon.Calendar,
  component: CalendarApp,
  route: PrivateRoute,
  exact: true,
  disableFromMobileMenu: true,
  activeUserOnly: false,
};

// menu utama berarti ada Kelas & Angkatan, Kalender Akademik, Transkrip Nilai, E-Syahadah, E-Student Card, Profil
const classRoutes: PageRoutes = {
  path: '/kelas',
  name: 'Kelas',
  component: ClassPage,
  icon: () => <i className="uil uil-book-open"></i>,
  route: PrivateRoute,
  exact: true,
  activeUserOnly: true,
  children: [
    {
      path: '/kelas/:id',
      name: 'Kelas Detail',
      component: ClassDetail,
      route: PrivateRoute,
      exact: true,
      disableFromMenu: true,
      children: [
        {
          path: '/kelas/:id/mata-kuliah/:mataKuliahId',
          name: 'Mata Kuliah Detail',
          component: MataKuliahDetail,
          route: PrivateRoute,
          exact: true,
          children: [
            {
              path: '/kelas/:id/mata-kuliah/:mataKuliahId/sub-matkul/:subMatkulId',
              name: 'Sub Mata Kuliah',
              component: SubMatkulDetail,
              route: PrivateRoute,
              exact: true,
              children: [
                {
                  path:
                    '/kelas/:id/mata-kuliah/:mataKuliahId/sub-matkul/:subMatkulId/materi/:materiId',
                  name: 'Materi Pelajaran Detail',
                  component: MateriPelajaranDetail,
                  route: PrivateRoute,
                  exact: true,
                },
                {
                  path:
                    '/kelas/:id/mata-kuliah/:mataKuliahId/sub-matkul/:subMatkulId/ujian/:ujianId',
                  name: 'Ujian',
                  component: UjianPage,
                  route: PrivateRoute,
                  exact: true,
                },
                {
                  path:
                    '/kelas/:id/mata-kuliah/:mataKuliahId/sub-matkul/:subMatkulId/ujian/:ujianId/hasil',
                  name: 'Hasil Ujian',
                  component: FeedbackUjianPage,
                  route: PrivateRoute,
                  exact: true,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const transcriptsRoutes: PageRoutes = {
  path: '/transkrip-nilai',
  name: 'Transkrip Nilai',
  component: TranskripPage,
  icon: FeatherIcon.Clipboard,
  route: PrivateRoute,
  exact: true,
  disableFromMobileMenu: true,
  activeUserOnly: true,
  children: [
    {
      path: '/transkrip-nilai/course/:id',
      name: 'Transkrip Detail',
      component: TranskripDetailPage,
      route: PrivateRoute,
      exact: true,
      disableFromMenu: true,
    },
  ],
};

const irsRoutes: PageRoutes = {
  path: '/irs',
  name: 'Isian Rencana Studi',
  component: () => <IrsPage />,
  icon: () => <i className="uil uil-university"></i>,
  route: PrivateRoute,
  exact: true,
  disableFromMobileMenu: true,
  activeUserOnly: true,
  children: [
    {
      path: '/irs/create',
      name: 'Create IRS',
      component: () => <IrsFormPage create={true} />,
      route: PrivateRoute,
      exact: true,
      disableFromMenu: true,
    },
    {
      path: '/irs/plan/:id',
      name: 'IRS Plan',
      component: IrsPlanPage,
      route: PrivateRoute,
      exact: true,
      disableFromMenu: true,
    },
    {
      path: '/irs/plan/:id/edit',
      name: 'Edit IRS Plan',
      component: () => <IrsPlanEditPage />,
      route: PrivateRoute,
      exact: true,
      disableFromMenu: true,
    },
  ],
};

// const eStudentCardRoutes: PageRoutes = {
//   path: '/e-student-card',
//   name: 'E-Student Card',
//   component: StudentCardPage,
//   icon: () => <i className="uil uil-user-square"></i>,
//   route: PrivateRoute,
//   exact: true,
//   activeUserOnly: true,
// };

const profileRoutes = {
  path: '/profile',
  header: 'Pengaturan',
  name: 'Profil',
  component: Profile,
  icon: FeatherIcon.User,
  route: PrivateRoute,
  exact: true,
  activeUserOnly: false,
};

const prayerTimesRoutes: PageRoutes = {
  path: '/prayer-times',
  name: 'Jadwal Shalat',
  component: PrayerTimes,
  icon: FeatherIcon.Clock,
  route: PrivateRoute,
  exact: true,
  activeUserOnly: false,
};

const surveyRoutes: PageRoutes = {
  path: '/survey',
  name: 'Survey',
  component: SurveyPage,
  icon: () => <i className="uil uil-book-open"></i>,
  route: PrivateRoute,
  exact: true,
  disableFromMenu: true,
  activeUserOnly: false,
  disableFromMobileMenu: true,
  children: [
    {
      path: '/survey/:id',
      name: 'Survey Detail',
      component: SurveyDetailPage,
      route: PrivateRoute,
      exact: true,
      disableFromMenu: true,
    },
  ],
};

const authRoutes = {
  path: '/account',
  name: 'Auth',
  component: () => <div>Authentication</div>,
  route: Route,
  exact: true,
  children: [
    {
      path: '/account/login',
      name: 'Login',
      component: Login,
      route: Route,
      exact: false,
    },
    {
      path: '/account/logout',
      name: 'Logout',
      component: Logout,
      route: Route,
      exact: false,
    },
    {
      path: '/account/forget-password',
      name: 'ForgetPassword',
      component: ForgetPassword,
      route: Route,
      exact: false,
    },
    {
      path: '/account/reset-password/:resetId',
      name: 'ResetPassword',
      component: ResetPassword,
      route: Route,
      exact: false,
    },
  ],
};

const videoRoutes: PageRoutes = {
  path: '/video',
  name: 'Video',
  icon: FeatherIcon.Youtube,
  component: Video,
  route: PrivateRoute,
  exact: true,
  disableFromMenu: true,
  activeUserOnly: false,
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes: Array<any> = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const allRoutes = [
  rootRoute,
  dashboardRoutes,
  calendarAppRoutes,
  classRoutes,
  transcriptsRoutes,
  irsRoutes,
  prayerTimesRoutes,
  videoRoutes,
  profileRoutes,
  authRoutes,
  surveyRoutes,
];

const authProtectedRoutes = [
  rootRoute,
  dashboardRoutes,
  calendarAppRoutes,
  classRoutes,
  transcriptsRoutes,
  irsRoutes,
  prayerTimesRoutes,
  videoRoutes,
  profileRoutes,
  surveyRoutes,
];

const flattenedAuthRoutes = flattenRoutes([authRoutes]);
const flattenedPrivateRoutes = flattenRoutes(authProtectedRoutes);

export { allRoutes, authProtectedRoutes, flattenedAuthRoutes, flattenedPrivateRoutes };
