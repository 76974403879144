import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';

import imgNotFound from 'assets/images/not-found.png';
import logo from 'assets/images/tsl-logo-1.png';

const Error404 = () => {
  const { pathname } = useLocation();

  return (
    <div className="account-pages my-5">
      <Container>
        <Row className="justify-content-center">
          <Col xl={4} lg={5}>
            <div className="text-center">
              <div>
                <img src={logo} alt="" className="img-fluid" width={200} />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="text-center">
            <h3 className="mt-3">404 - Halaman tidak ditemukan</h3>
            <h5 className="">{pathname}</h5>
            <p className="text-muted mb-5">
              Halaman yg antum cari tidak ditemukan. Apakah URL yg diketik sudah benar?
              <br />
              Jika antum merasa ini adalah sebuah kesalahan / bug, silahkan hubungi tim support TSL.
            </p>

            <Link to="/" className="btn btn-lg btn-primary mt-4">
              Kembali ke Home
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Error404;
